import { Box, Stack, Typography } from "@mui/material";


const SelectCrypto = ({ value, iconUrl }) => {
    return (
        <Stack direction='row' alignItems='center'>
            <Box sx={{
                background: iconUrl,
                width: '32px',
                height: '32px',
                marginRight: '5px'
            }}>
            </Box>
            <Typography sx={{ color: '#764428', fontSize: '14px', fontWeight: '700' }}>
                {value}
            </Typography>
        </Stack>
    );
};

export default SelectCrypto;