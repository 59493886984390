import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const LoadingCircle = () => {
    return (<Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        }}
    >
        <CircularProgress sx={{ color: '#FAD21D' }} />
    </Box>);
};

export default LoadingCircle;
