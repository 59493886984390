import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}



export default function Alert({ open, handleClose, Transition, message }) {
    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            TransitionComponent={SlideTransition} // Используем SlideTransition
            message={message}
            key={message} // Используем message как ключ
            autoHideDuration={1200}
        />
    );
}