import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import Header from "../Components/Header";
import Navigation from "../Components/Navigation";
import ButtonGreen from "../Components/Buttons/ButtonGreen";
import ModalWithdraw from "../Components/ModalWithdraw";
import { useEffect, useState } from "react";

import LoadingCircle from "../Components/LoadingCircle";

import dbUrls from '../database/dbUrls';

function Stake({ pool }) {

    const [open, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true)
    const handleCloseModal = () => setOpenModal(false)

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    const [selectLang, setSelectLang] = useState('en');
    const [languages, setLanguages] = useState({});

    const [stakingInfo, setStakingInfo] = useState({});

    const [courseCati, setCourseCati] = useState(0);

    useEffect(() => {
        const fetchData = async (url, body) => {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            if (body) {
                options.body = JSON.stringify(body);
            }

            const response = await fetch(url, options);

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        };

        const fetchUserData = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.expand();

                const telegramID = window.Telegram.WebApp.initDataUnsafe.user.id;

                try {
                    const user = await fetchData(new dbUrls().users.getUser(), { user_id: telegramID });
                    setUser(user);
                    setSelectLang(user.language);

                    const [lang, stakingInfo, priceCati] = await Promise.all([
                        fetchData(new dbUrls().languages.getLang(), { page: 'stake' }),
                        fetchData(new dbUrls().staking.info(), { user_id: telegramID }),
                        fetchData(new dbUrls().merchant.priceCati()),
                    ]);

                    setLanguages(lang);
                    setStakingInfo(stakingInfo);
                    setCourseCati(priceCati.price);

                    // Кнопка НАЗАД
                    window.Telegram.WebApp.BackButton.hide();
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchUserData();
    }, []);


    if (loading) {
        return <LoadingCircle />;
    }

    return (<>
        <Box sx={{
            backgroundImage: 'url(/bg.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain', // Сохраняем 'contain' для мобильных
            backgroundPosition: 'center',
            maxWidth: '600px',
            height: '100%',
            overflowX: 'hidden', // Скрыть переполнение
            width: '100%', // Установить ширину на 100% для адаптивности
            '@media (max-width: 600px)': { // Адаптивные стили для мобильных устройств
                height: '100%', // Автоматическая высота для мобильных
                backgroundSize: 'cover', // Изменить на cover для мобильных
            }
        }}>
            <Container maxWidth='600px' sx={{
                paddingTop: '20px',
                paddingBottom: '40px',
                height: '100%', // Убедитесь, что контейнер занимает всю высоту
            }}>
                <Header handleOpenModal={handleOpenModal} user={user} selectLang={selectLang} setSelectLang={setSelectLang} languages={languages.header} />
                <Navigation />
                <Stack sx={{ marginTop: '20px' }} spacing={2}>
                    <Stack>
                        <Typography textAlign='center' sx={{ color: '#764428', fontSize: '28px', fontWeight: '700' }}>
                            {languages.page.title[selectLang]}
                        </Typography>
                        <Typography textAlign='center' sx={{ color: '#BC805E', fontSize: '20px', fontWeight: '400' }}>
                            {languages.page.description[selectLang] + `${courseCati} $`}
                        </Typography>
                    </Stack>
                    <Stack alignItems='center'>
                        <Box sx={{
                            background: 'url(pool.png)',
                            width: '361px',
                            height: '427px',
                            position: 'relative',
                            padding: '60px 30px'
                        }}>
                            <Box
                                component="img"
                                src="star300.png" // Укажите путь к вашему изображению
                                alt="Pool Table"
                                sx={{
                                    position: 'absolute',
                                    width: '71px',
                                    height: '77px',
                                    top: '10px',
                                    left: '5%',
                                }}
                            />
                            <Box
                                component="img"
                                src="pool_table.png" // Укажите путь к вашему изображению
                                alt="Pool Table"
                                sx={{
                                    position: 'absolute',
                                    width: '110px',
                                    height: '36px',
                                    top: '30px',
                                    left: '33.5%',
                                }}
                            />
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    top: '47px',
                                    left: '49%',

                                    transform: 'translate(-50%, -50%)', // Центрируем текст по изображению
                                    color: '#764428', // Цвет текста
                                    fontWeight: '700', // Жирный текст
                                    textAlign: 'center', // Центрируем текст
                                }}
                            >
                                {languages.page.pool[selectLang]} №1
                            </Typography>
                            <Stack direction='column'>
                                <Stack direction='row' justifyContent='center' alignItems='center'>
                                    <Box sx={{
                                        background: "url('money.png')",
                                        backgroundRepeat: 'no-repeat',
                                        width: '60px',
                                        height: '60px',
                                        marginRight: '5px'
                                    }}>

                                    </Box>
                                    <Typography sx={{ fontSize: '64px', fontWeight: '800', color: "#FFD92D", WebkitTextStroke: '2px #DF671B' }}>
                                        {stakingInfo.in_staking_first}
                                    </Typography>
                                </Stack>
                                <Stack direction='column' >
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.award[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {pool.first.awards} Cati
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ bgcolor: '#CCA47C' }}></Divider>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.pool_apy[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {pool.first.catiApy}
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ bgcolor: '#CCA47C' }}></Divider>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.my_award[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {stakingInfo.my_award_first} Cati
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ bgcolor: '#CCA47C' }}></Divider>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.awards_received[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {stakingInfo.collect_first} Cati
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ bgcolor: '#CCA47C' }}></Divider>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.uncollected_awards[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {stakingInfo.uncollect_first} Cati
                                        </Typography >
                                    </Stack >
                                </Stack>
                                <ButtonGreen to='/stacking/1' text={languages.page.choose[selectLang]} />
                            </Stack>

                        </Box>
                    </Stack>
                    <Stack alignItems='center'>
                        <Box sx={{
                            background: 'url(/pool.png)',
                            width: '361px',
                            height: '427px',
                            position: 'relative',
                            padding: '60px 30px'
                        }}>
                            <Box
                                component="img"
                                src="/star500.png" // Укажите путь к вашему изображению
                                alt="Pool Table"
                                sx={{
                                    position: 'absolute',
                                    width: '71px',
                                    height: '77px',
                                    top: '10px',
                                    left: '5%',
                                }}
                            />
                            <Box
                                component="img"
                                src="/pool_table.png" // Укажите путь к вашему изображению
                                alt="Pool Table"
                                sx={{
                                    position: 'absolute',
                                    width: '110px',
                                    height: '36px',
                                    top: '30px',
                                    left: '33.5%',
                                }}
                            />
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    top: '47px',
                                    left: '49%',

                                    transform: 'translate(-50%, -50%)', // Центрируем текст по изображению
                                    color: '#764428', // Цвет текста
                                    fontWeight: '700', // Жирный текст
                                    textAlign: 'center', // Центрируем текст
                                }}
                            >
                                {languages.page.pool[selectLang]} №2
                            </Typography>
                            <Stack direction='column'>
                                <Stack direction='row' justifyContent='center' alignItems='center'>
                                    <Box sx={{
                                        background: "url('/money.png')",
                                        backgroundRepeat: 'no-repeat',
                                        width: '60px',
                                        height: '60px',
                                        marginRight: '5px'
                                    }}>

                                    </Box>
                                    <Typography sx={{ fontSize: '64px', fontWeight: '800', color: "#FFD92D", WebkitTextStroke: '2px #DF671B' }}>
                                        {stakingInfo.in_staking_second}
                                    </Typography>
                                </Stack>
                                <Stack direction='column' >
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.award[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {pool.second.awards} Cati
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ bgcolor: '#CCA47C' }}></Divider>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.pool_apy[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {pool.second.catiApy}
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ bgcolor: '#CCA47C' }}></Divider>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.my_award[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {stakingInfo.my_award_second} Cati
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ bgcolor: '#CCA47C' }}></Divider>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.awards_received[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {stakingInfo.collect_second} Cati
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ bgcolor: '#CCA47C' }}></Divider>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.uncollected_awards[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {stakingInfo.uncollect_second} Cati
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <ButtonGreen to='/stacking/2' text={languages.page.choose[selectLang]} />
                            </Stack>
                        </Box>
                    </Stack>
                </Stack>
                <ModalWithdraw open={open} handleCloseModal={handleCloseModal} languages={languages.withdraw} selectLang={selectLang} />
            </Container>
        </Box >
    </>);
}

export default Stake;