import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Welcome from './Pages/Welcome';
import Stake from './Pages/Stake';
import Airdrop from "./Pages/Airdrop"
import Stacking from "./Pages/Stacking"

import LoadingCircle from "./Components/LoadingCircle";

import dbUrls from '../src/database/dbUrls';

function App() {
    const [initRoute, setInitRoute] = useState('/welcome');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // const setAppHeight = () => {
        //     const appHeight = window.innerHeight;
        //     document.documentElement.style.setProperty('--app-height', `${appHeight}px`);
        // };

        const fetchUserData = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.expand();
                window.Telegram.WebApp.setBackgroundColor("#F7F2D2");
                window.Telegram.WebApp.setBottomBarColor("#F7F2D2");
                window.Telegram.WebApp.setHeaderColor("#F7F2D2");

                try {
                    const response = await fetch(new dbUrls().users.getOrCreateUser(), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ user: window.Telegram.WebApp.initDataUnsafe.user }),
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const user = await response.json();

                    if (user.open_web) {
                        setInitRoute('/stake');
                    } else {
                        setInitRoute('/welcome');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        // setAppHeight();
        // window.addEventListener('resize', setAppHeight);
        fetchUserData();
        // return () => {
        //     window.removeEventListener('resize', setAppHeight);
        // };
    }, []);

    if (loading) {
        return <LoadingCircle />;
    }

    const pool = {
        first: {
            awards: '50 000 000',
            catiApy: '10 000%',
            myBet: '0 Cati',
            awardsReceived: '0 Cati',
            unAwardsReceived: '0 Cati',
        },
        second: {
            awards: '200 000 000',
            catiApy: '30 000%',
            myBet: '0 Cati',
            awardsReceived: '0 Cati',
            unAwardsReceived: '0 Cati',
        }
    }

    return (<>
        <Routes>
            <Route path="/" element={<Navigate to={initRoute} replace />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/stake" element={<Stake pool={pool} />} />
            <Route path="/airdrop" element={<Airdrop></Airdrop>}></Route>
            <Route path="/stacking/:id" element={<Stacking pool={pool}></Stacking>}></Route>
        </Routes>
    </>)
}

export default App