import { Box, Container, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import LoadingCircle from "../Components/LoadingCircle";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dbUrls from '../database/dbUrls';

function Welcome() {
    const [loading, setLoading] = useState(true);
    const MotionBox = motion(Box);
    const navigate = useNavigate();

    const onClick = async () => {
        const telegramID = window.Telegram.WebApp.initDataUnsafe.user.id;

        try {
            const response = await fetch(new dbUrls().users.updateOpenWeb(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: telegramID }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }

        navigate('/stake')
    };

    useEffect(() => {
        const loadingFunc = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.expand();
                window.Telegram.WebApp.BackButton.hide();
                setLoading(false);
            }
        };

        loadingFunc();
    }, []);

    if (loading) {
        return <LoadingCircle />;
    }

    return (
        <>
            <Box
                sx={{
                    background: "url(bg_cati.jpg)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain", // Сохраняем 'contain' для мобильных
                    backgroundPosition: "center",
                    maxWidth: "600px",
                    height: "100vh",
                    overflow: "hidden", // Скрыть переполнение
                    width: "100%", // Установить ширину на 100% для адаптивности
                    "@media (max-width: 600px)": {
                        // Адаптивные стили для мобильных устройств
                        height: "100vh", // Автоматическая высота для мобильных
                        backgroundSize: "cover", // Изменить на cover для мобильных
                    },
                }}
            >
                <Container
                    maxWidth="600px"
                    sx={{
                        paddingTop: "150px",
                        paddingBottom: "40px",
                        height: "100%", // Убедитесь, что контейнер занимает всю высоту
                    }}
                >
                    <Stack
                        direction="column"
                        justifyContent="space-between"
                        sx={{ height: "100%" }}
                    >
                        <Stack>
                            <Typography
                                textAlign="center"
                                sx={{
                                    fontSize: "40px",
                                    color: "#fff",
                                    fontWeight: "700",
                                    WebkitTextStroke: "1px #9B8008",
                                    display: "inline",
                                    lineHeight: "22px",
                                    fontFamily: "FredokaOne",
                                    fontStyle: 'normal',
                                }}
                            >
                                Congratulations!
                            </Typography>
                            <Typography
                                textAlign="center"
                                marginTop='20px'
                                sx={{
                                    fontSize: "28px",
                                    color: "#fff",
                                    fontWeight: "700",
                                    WebkitTextStroke: "1px #9B8008",
                                    display: "inline",
                                    lineHeight: "22px",
                                    fontFamily: "FredokaOne",
                                    fontStyle: 'normal',
                                }}
                            >
                                You've received 1000 Cati!
                            </Typography>
                        </Stack>
                        <Stack alignItems="center">
                            <Typography
                                textAlign='left'
                                sx={{
                                    fontSize: "16px",
                                    color: "#fff",
                                    fontWeight: "700",
                                    WebkitTextStroke: "1px #6E5A00",
                                    display: "inline",
                                    textShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                                    borderRadius: '10px',
                                    lineHeight: "22px",
                                    zIndex: 1,
                                    fontFamily: "FredokaOne",
                                }}
                            >
                                We’re thrilled to reward you with these coins as a token of our
                                appreciation for being a valued user of our app. Your journey
                                with us is important, and we can’t wait to see how you use your
                                Cati to enhance your experience. Keep exploring, and enjoy
                                all the exciting features we have in store for you! Happy
                                spending!
                            </Typography>
                            <MotionBox
                                onClick={() => onClick()}
                                sx={{
                                    backgroundImage: 'url(btn_next.png)',
                                    marginTop: '10px',
                                    width: '360px',
                                    height: '52px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    // margin: '20px 0px'
                                }}
                                whileTap={{ scale: 0.9 }} // Уменьшение при нажатии
                                transition={{
                                    type: 'spring',
                                    stiffness: 500,
                                    damping: 15
                                }}
                            >
                            </MotionBox>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </>
    );
}

export default Welcome;