class dbStaking {
    constructor() {
        this.url = 'https://catiairdrops.com/api/staking';
    }

    info() {
        return this.url + '/info';
    }

    detailInfo() {
        return this.url + '/detail_info';
    }
}

module.exports = dbStaking;