import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function ButtonGreen({ to, text }) {
    const MotionBox = motion(Box);
    const navigate = useNavigate();
    return (
        <MotionBox
            onClick={() => navigate(to)}
            sx={{
                backgroundImage: 'url(button_green.png)',
                width: '307px',
                height: '41px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                margin: '20px 0px'
            }}
            whileTap={{ scale: 0.9 }} // Уменьшение при нажатии
            transition={{
                type: 'spring',
                stiffness: 500,
                damping: 15
            }}
        >
            <Typography sx={{
                marginBottom: '8px',
                color: '#fff',
                fontWeight: '800',
                fontSize: '24px',
                WebkitTextStroke: '1px #76B122',
            }}>
                {text}
            </Typography>
        </MotionBox>
    );
}

export default ButtonGreen;