import { Box, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import ButtonYellow from "../Components/Buttons/ButtonYellow";

import { useTonConnectUI } from "@tonconnect/ui-react";

import dbUrls from '../database/dbUrls';

function Header({ handleOpenModal, user, selectLang, setSelectLang, languages }) {
    const MotionStack = motion(Stack);
    const [langIsOpen, setLang] = useState(false);
    const [menuIsOpen, setMenu] = useState(false);

    const [_, setOptions] = useTonConnectUI({ language: selectLang });

    const handleCloseMenu = () => {
        setMenu(false);
    };

    return (
        <Stack direction='row' justifyContent='space-between' spacing={1}>
            <MotionStack
                onClick={() => {
                    setLang(!langIsOpen);
                    setMenu(false);
                }}
                position='relative'
                direction='row'
                alignItems='center'
                spacing={2}
                sx={{ bgcolor: '#DEC89B', borderRadius: '7px', border: '2px solid #996844', padding: '3px 5px', cursor: 'pointer' }}
                whileTap={{ scale: 0.95 }} // Уменьшение при нажатии
                transition={{
                    type: 'spring',
                    stiffness: 500,
                    damping: 15
                }}>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        component="img"
                        src={selectLang === 'ru' ? "/ru.svg" : "/en.svg"} // Укажите путь к вашей иконке
                        alt="Custom Icon"
                        sx={{ width: '24px', height: '24px', marginRight: '5px' }} // Настройте размер и отступ
                    />
                    <Typography sx={{
                        color: '#FFFFFF',
                        fontWeight: '800',
                        fontSize: '21px',
                        WebkitTextStroke: '1px #764428',
                    }}>
                        {selectLang === 'ru' ? "Русский" : "English"}
                    </Typography>
                </Box>
                <ExpandMoreIcon sx={{
                    color: "#65371F",
                    transform: !langIsOpen ? 'rotate(0deg)' : 'rotate(180deg)', // Поворот на 90 градусов
                    transition: 'transform 0.3s ease', // Плавный переход (опционально)
                }} />
            </MotionStack>
            {langIsOpen ? (
                <MotionStack
                    onClick={async () => {
                        setLang(false);
                        setSelectLang(selectLang === 'ru' ? "en" : "ru")
                        setOptions({ language: selectLang === 'ru' ? "en" : "ru" })

                        try {
                            const response = await fetch(new dbUrls().users.switchLanguage(), {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({ user_id: user.user_id }),
                            });

                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }

                        } catch (error) {
                            console.error('Error fetching lang data:', error);
                        }
                    }}
                    position='absolute'
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    sx={{
                        bgcolor: '#DEC89B', borderRadius: '7px', border: '2px solid #996844', padding: '3px 5px', width: '151.45px', cursor: 'pointer',
                        top: '70px',
                        left: '9px'
                    }}
                    whileTap={{ scale: 0.95 }} // Уменьшение при нажатии
                    initial={{ opacity: 0, y: -20 }} // Начальное состояние
                    animate={{ opacity: 1, y: 0 }} // Конечное состояние
                    exit={{ opacity: 0, y: -20 }} // Состояние при выходе
                    transition={{
                        type: 'spring',
                        stiffness: 500,
                        damping: 15
                    }}>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                            component="img"
                            src={selectLang === 'ru' ? "/en.svg" : "/ru.svg"} // Укажите путь к вашей иконке
                            alt="Custom Icon"
                            sx={{ width: '24px', height: '24px', marginRight: '5px' }} // Настройте размер и отступ
                        />
                        <Typography sx={{
                            color: '#FFFFFF',
                            fontWeight: '800',
                            fontSize: '21px',
                            WebkitTextStroke: '1px #764428',
                        }}>
                            {selectLang === 'ru' ? "English" : "Русский"}
                        </Typography>
                    </Box>
                </MotionStack>
            ) : null}
            <MotionStack
                position='relative'
                onClick={() => {
                    setMenu(!menuIsOpen);
                    setLang(false);
                }}
                direction='row'
                alignItems='center'
                spacing={2}
                sx={{ bgcolor: '#DEC89B', borderRadius: '7px', border: '2px solid #996844', padding: '3px 10px', cursor: 'pointer' }}
                whileTap={{ scale: 0.95 }} // Уменьшение при нажатии
                transition={{
                    type: 'spring',
                    stiffness: 500,
                    damping: 15
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        component="img"
                        src="/coin.svg" // Укажите путь к вашей иконке
                        alt="Custom Icon"
                        sx={{ width: '24px', height: '24px', marginRight: '5px' }} // Настройте размер и отступ
                    />
                    <Typography sx={{
                        color: '#FFD92D',
                        fontWeight: '800',
                        fontSize: '21px',
                        WebkitTextStroke: '1px #DF671B',
                    }}>
                        {user.balance}
                    </Typography>
                </Box>
                <Box
                    component="img"
                    src="/list_icon.svg" // Укажите путь к вашей иконке
                    alt="Custom Icon"
                    sx={{ width: '24px', height: '24px', marginRight: '5px' }} // Настройте размер и отступ
                />
            </MotionStack>
            {menuIsOpen ? (
                <MotionStack
                    position='absolute'
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    sx={{
                        bgcolor: '#FEFBFF',
                        borderRadius: '7px',
                        border: '2px solid #996547',
                        cursor: 'pointer',
                        right: '15px',
                        top: '70px',
                        padding: '20px 16px'
                    }}
                    initial={{ opacity: 0, y: -30 }} // Начальное состояние
                    animate={{ opacity: 1, y: 0 }} // Конечное состояние
                    exit={{ opacity: 0, y: -30 }} // Состояние при выходе
                    transition={{
                        type: 'spring',
                        stiffness: 500,
                        damping: 15
                    }}
                >
                    <ButtonYellow handleCloseMenu={handleCloseMenu} handleOpenModal={handleOpenModal} text={languages.withdraw[selectLang]} />
                </MotionStack>
            ) : null}
        </Stack>
    );
}

export default Header;
