import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";

function ButtonYellow({ handleOpenModal, handleCloseMenu, text }) {
    const MotionBox = motion(Box);
    return (
        <MotionBox
            onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleOpenModal()
                handleCloseMenu(false)
            }}
            sx={{
                backgroundImage: 'url(/button_yellow.png)',
                width: '163px',
                height: '38px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                margin: '20px 0px'
            }}
            whileTap={{ scale: 0.9 }} // Уменьшение при нажатии
            transition={{
                type: 'spring',
                stiffness: 500,
                damping: 15
            }}
        >
            <Typography sx={{
                marginBottom: '8px',
                color: '#fff',
                fontWeight: '800',
                fontSize: '18px',
                WebkitTextStroke: '0.5px #EBAB00',
            }}>
                {text}
            </Typography>
        </MotionBox>
    );
}

export default ButtonYellow;