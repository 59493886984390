import { useState, useEffect } from 'react';
import { Typography, Box, Stack } from '@mui/material';
import { motion } from 'framer-motion';


const Timer = ({ selectLang }) => {
    const [timeLeft, setTimeLeft] = useState(0); // Время в секундах

    useEffect(() => {
        // Задайте дату и время, до которого будет отсчитываться таймер
        const targetDate = new Date('2024-12-20T18:00:00'); // Замените на нужную дату и время

        const calculateTimeLeft = () => {
            const now = new Date();
            const difference = targetDate - now; // Разница в миллисекундах
            const totalSeconds = Math.floor(difference / 1000); // Преобразование в секунды
            setTimeLeft(totalSeconds);
        };

        calculateTimeLeft(); // Инициализация времени при загрузке

        const timer = setInterval(() => {
            calculateTimeLeft(); // Обновление времени каждую секунду
        }, 1000);

        return () => clearInterval(timer); // Очистка интервала при размонтировании
    }, []);

    const formatTime = (totalSeconds) => {
        const days = Math.floor(totalSeconds / 86400);
        const hours = Math.floor((totalSeconds % 86400) / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return { days, hours, minutes, seconds };
    };

    const { days, hours, minutes, seconds } = formatTime(timeLeft);

    return (
        <Box textAlign="center" mt={2}>

            <Stack direction='row' justifyContent='center' spacing={1}>
                <Stack sx={{ padding: '5px', bgcolor: '#DEC89B', borderRadius: '10px', border: '2px solid #996844' }}>
                    <motion.div
                        key={days} // Ключ для анимации
                        initial={{ opacity: 0, y: -20, scale: 1 }}
                        animate={{
                            opacity: 1, y: 0, scale: 1
                        }}
                        transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    >
                        <Typography sx={{ fontSize: '14px', fontWeight: '700', color: "#764428" }}>
                            {days} {selectLang === 'ru'
                                ? (days % 10 === 1 && days % 100 !== 11 ? 'день' :
                                    (days % 10 >= 2 && days % 10 <= 4 && (days % 100 < 10 || days % 100 >= 20) ? 'дня' : 'дней'))
                                : (days > 1 ? 'days' : 'day')}
                        </Typography>
                    </motion.div>

                </Stack>
                <Stack sx={{ padding: '5px', bgcolor: '#DEC89B', borderRadius: '10px', border: '2px solid #996844' }}>
                    <motion.div
                        key={hours} // Ключ для анимации
                        initial={{ opacity: 0, y: -20, scale: 1 }}
                        animate={{
                            opacity: 1, y: 0, scale: 1
                        }}
                        transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    >
                        <Typography sx={{ fontSize: '14px', fontWeight: '700', color: "#764428" }}>
                            {hours} {selectLang === 'ru'
                                ? (hours % 10 === 1 && hours % 100 !== 11 ? 'час' :
                                    (hours % 10 >= 2 && hours % 10 <= 4 && (hours % 100 < 10 || hours % 100 >= 20) ? 'часа' : 'часов'))
                                : (hours > 1 ? 'hours' : 'hour')}
                        </Typography>
                    </motion.div>

                </Stack>
                <Stack sx={{ padding: '5px', bgcolor: '#DEC89B', borderRadius: '10px', border: '2px solid #996844' }}>
                    <motion.div
                        key={minutes} // Ключ для анимации
                        initial={{ opacity: 0, y: -20, scale: 1 }}
                        animate={{
                            opacity: 1, y: 0, scale: 1
                        }}
                        transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    >
                        <Typography sx={{ fontSize: '14px', fontWeight: '700', color: "#764428" }}>
                            {minutes} {selectLang === 'ru'
                                ? (minutes % 10 === 1 && minutes % 100 !== 11 ? 'минута' :
                                    (minutes % 10 >= 2 && minutes % 10 <= 4 && (minutes % 100 < 10 || minutes % 100 >= 20) ? 'минуты' : 'минут'))
                                : (minutes > 1 ? 'minutes' : 'minute')}
                        </Typography>
                    </motion.div>

                </Stack>
                <Stack sx={{ padding: '5px', bgcolor: '#DEC89B', borderRadius: '10px', border: '2px solid #996844' }}>
                    <motion.div
                        key={seconds} // Ключ для анимации
                        initial={{ opacity: 0, y: -20, scale: 1 }}
                        animate={{
                            opacity: 1, y: 0, scale: 1
                        }}
                        transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    >
                        <Typography sx={{ fontSize: '14px', fontWeight: '700', color: "#764428" }}>
                            {seconds} {selectLang === 'ru'
                                ? (seconds % 10 === 1 && seconds % 100 !== 11 ? 'секунда' :
                                    (seconds % 10 >= 2 && seconds % 10 <= 4 && (seconds % 100 < 10 || seconds % 100 >= 20) ? 'секунды' : 'секунд'))
                                : (seconds > 1 ? 'seconds' : 'second')}
                        </Typography>
                    </motion.div>
                </Stack>
            </Stack>

        </Box>
    );
};

export default Timer;