const dbUsers = require('./users');
const dbLanguages = require('./languages');
const dbMerchant = require('./merchant');
const dbStaking = require('./staking');

class dbUrls {
    get users() {
        return new dbUsers();
    }

    get languages() {
        return new dbLanguages();
    }

    get merchant() {
        return new dbMerchant();
    }

    get staking() {
        return new dbStaking();
    }
}

module.exports = dbUrls;