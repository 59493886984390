import { Box, InputAdornment, Modal, Stack, TextField, Typography } from "@mui/material";
import WalletIcon from '@mui/icons-material/Wallet';
import { motion } from "framer-motion";
import ButtonClose from "./Buttons/ButtonClose";
import ButtonWithdraw from "./Buttons/ButtonWithdraw";
import { useState } from "react";

function ModalWithdraw({ open, handleCloseModal, languages, selectLang }) {
    const [address, setAddress] = useState('')
    const [vicati, setVicati] = useState('')

    const handleWithdraw = () => {
        window.Telegram.WebApp.showAlert(languages.can_withdraw[selectLang]);
    }
    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <motion.div
                initial={{ opacity: 0 }} // Начальное состояние
                animate={{ opacity: 1 }} // Конечное состояние
                exit={{ opacity: 0 }} // Состояние при выходе
                transition={{
                    duration: 0.3, // Длительность анимации
                    type: 'ease',
                    stiffness: 300,
                    damping: 15
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '361px',
                    height: '370px',
                    padding: '0px 30px',
                    bgcolor: 'background.paper',
                    // boxShadow: 24,
                    // p: 4,
                    background: 'url("/modal.png")',
                }}

                >
                    <Stack mt={8}>
                        <Stack>
                            <Typography textAlign='start' sx={{ marginLeft: '10px', fontSize: '18px', color: '#764428', fontWeight: '700' }}>
                                {languages.address[selectLang]}
                            </Typography>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                value={address || ''}
                                onChange={(e) => setAddress(e.target.value)}

                                sx={{
                                    width: '100%',
                                    marginTop: '5px',
                                    backgroundColor: '#DEC7A5', // Цвет фона для TextField
                                    borderRadius: '10px', // Закругление углов
                                    // border: '1px solid #764428',
                                    marginBottom: '10px',
                                    '& .MuiOutlinedInput-root': {

                                        '& fieldset': {
                                            borderColor: 'transparent', // Цвет границы
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'transparent', // Цвет границы

                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'transparent', // Цвет границы

                                        },
                                        '& .MuiInputBase-input': { // Исправленный селектор
                                            padding: '10px 10px !important', // Установите нужные отступы
                                        }
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <WalletIcon sx={{ color: '#472A1A' }} /> {/* Исправленный тег закрытия */}
                                        </InputAdornment>
                                    ),
                                }}

                            />
                        </Stack>
                        <Stack>
                            <Typography textAlign='start' sx={{ marginLeft: '10px', fontSize: '18px', color: '#764428', fontWeight: '700' }}>
                                {languages.amount[selectLang]}
                            </Typography>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                value={vicati || ''}
                                onChange={(e) => setVicati(e.target.value)}
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                    backgroundColor: '#DEC7A5', // Цвет фона для TextField
                                    borderRadius: '10px', // Закругление углов
                                    // border: '1px solid #764428',
                                    marginBottom: '10px',
                                    '& .MuiOutlinedInput-root': {

                                        '& fieldset': {
                                            borderColor: 'transparent', // Цвет границы
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'transparent', // Цвет границы

                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'transparent', // Цвет границы

                                        },
                                        '& .MuiInputBase-input': { // Исправленный селектор
                                            padding: '10px 10px !important', // Установите нужные отступы
                                        }
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <span style={{ color: '#472A1A', fontWeight: 'bold', fontSize: '24px' }}>$</span>
                                        </InputAdornment>
                                    ),
                                }}

                            />
                        </Stack>
                        <ButtonWithdraw handleWithdraw={handleWithdraw} text={languages.withdraw[selectLang]} />
                        <ButtonClose handleCloseModal={handleCloseModal} text={languages.close[selectLang]} />
                    </Stack>

                </Box>
            </motion.div>
        </Modal>
    );
}

export default ModalWithdraw;