import { Box, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";

function ButtonWithdraw({ handleWithdraw, text }) {
    const MotionBox = motion(Box);
    return (
        <MotionBox
            onClick={handleWithdraw}
            sx={{
                backgroundImage: 'url(/button_withdraw.png)',
                width: '303px',
                height: '41px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                margin: '5px 0px'
            }}
            whileTap={{ scale: 0.9 }} // Уменьшение при нажатии
            transition={{
                type: 'spring',
                stiffness: 500,
                damping: 15
            }}
        >
            <Stack sx={{ position: 'relative' }}>
                <Box sx={{
                    position: 'absolute',
                    background: 'url("/button_withdraw_icon.svg")',
                    width: '20px',
                    height: '20px',
                    left: '-22px',
                    top: '8px'
                }}>

                </Box>
                <Typography sx={{
                    marginBottom: '10px',
                    color: '#fff',
                    fontWeight: '800',
                    fontSize: '24px',
                    WebkitTextStroke: '1px #EBAB00',
                }}>
                    {text}
                </Typography>
            </Stack>
        </MotionBox>
    );
}

export default ButtonWithdraw;