import { Box, Stack } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

function Navigation() {
    const location = useLocation(); // Получаем текущий путь
    return (
        <Stack direction='row' alignItems='center' spacing={1}
            sx={{
                position: 'fixed',
                bottom: '20px',
                left: '50%', // Центрируем по горизонтали
                transform: 'translateX(-50%)', // Сдвигаем влево на 50% ширины
                bgcolor: '#E7D7B2',
                border: '2px solid #764428',
                borderRadius: '50px',
                padding: '4px 8px',
                zIndex: '99'
            }}>
            <Link to="/stake" style={{ textDecoration: 'none' }}>
                <Box sx={{
                    background: 'url(/stacking_icon.svg)',
                    backgroundRepeat: 'no-repeat',
                    width: '38px',
                    height: '38px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    borderRadius: '50px',
                    bgcolor: location.pathname === '/stake' || location.pathname === '/stacking/1' || location.pathname === '/stacking/2' ? '#C19065' : 'transparent', // Изменение цвета для активного маршрута

                }}>
                </Box>
            </Link>
            <Link to="/airdrop" style={{ textDecoration: 'none' }}>
                <Box sx={{
                    background: 'url(/airdrop_icon.svg)',
                    backgroundRepeat: 'no-repeat',
                    width: '38px',
                    height: '38px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    borderRadius: '50px',
                    bgcolor: location.pathname === '/airdrop' ? '#C19065' : 'transparent', // Изменение цвета для активного маршрута

                }}>
                </Box>
            </Link>
        </Stack>
    );
}

export default Navigation;