class dbMerchant {
    constructor() {
        this.url = 'https://catiairdrops.com/api/merchant';
    }

    create() {
        return this.url + '/create';
    }

    priceCati() {
        return this.url + '/price_cati';
    }
}

module.exports = dbMerchant;