import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";

function ButtonClose({ handleCloseModal, text }) {
    const MotionBox = motion(Box);
    // const navigate = useNavigate();
    return (
        <MotionBox
            onClick={handleCloseModal}
            sx={{
                backgroundImage: 'url(/button_close.png)',
                width: '303px',
                height: '41px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                margin: '5px 0px'
            }}
            whileTap={{ scale: 0.9 }} // Уменьшение при нажатии
            transition={{
                type: 'spring',
                stiffness: 500,
                damping: 15
            }}
        >
            {/* Вы можете добавить текст или иконку внутри кнопки, если нужно */}
            <Typography sx={{
                marginBottom: '8px',
                color: '#fff',
                fontWeight: '800',
                fontSize: '20px',
                WebkitTextStroke: '1px #5E1616',
            }}>
                {text}
            </Typography>
        </MotionBox>
    );
}

export default ButtonClose;