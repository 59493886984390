import { Box, Container, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import Header from "../Components/Header";
import Navigation from "../Components/Navigation";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ButtonStake from "../Components/Buttons/ButtonStake";
import ModalWithdraw from "../Components/ModalWithdraw";
import TextInput from "../Components/TextInput";
import Alert from "../Components/Buttons/Alert";
import Fade from '@mui/material/Fade';

import LoadingCircle from "../Components/LoadingCircle";

import SelectCrypto from "../Components/SelectCrypto";

import dbUrls from '../database/dbUrls';


function Stacking({ pool }) {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    const [selectLang, setSelectLang] = useState('en');
    const [languages, setLanguages] = useState({});

    const [stakingInfo, setStakingInfo] = useState({});

    const navigate = useNavigate();

    const [openAlert, setOpenAlert] = useState(false);
    const [transition, setTransition] = useState(Fade);
    const [alertMessage, setAlertMessage] = useState(''); // Состояние для сообщения алерта

    const param = useParams();
    let id = param.id

    const [crypto, setCrypto] = useState('USDT TRC20');
    const [amount, setAmount] = useState(null);
    const [amountCrypto, setAmountCrypto] = useState(null);
    const [address, setAddress] = useState(null);

    const [displayAmount, setDisplayAmount] = useState('');
    const [displayCrypto, setDisplayCrypto] = useState('');
    const [displayAmountCrypto, setDisplayAmountCrypto] = useState('');

    const [open, setOpenModal] = useState(false);
    const [selectVisible, setSelectVisible] = useState(true);

    const [courseCati, setCourseCati] = useState(0);

    const handleButtonClick = async () => {
        if (!amount) {
            setAlertMessage(languages.page.error_amount_in_dollars[selectLang])
            handleClick(Fade)();
        } else {
            const regex = /^[1-9]\d*$/;

            if (regex.test(amount)) {
                if (parseInt(amount, 10) < 10) {
                    window.Telegram.WebApp.showAlert(languages.page.minimal_amount[selectLang]);
                    return;
                }

                setLoading(true);

                try {
                    const response = await fetch(new dbUrls().merchant.create(), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            user_id: user.user_id,
                            crypto: crypto,
                            amount_dollars: amount,
                            staking_id: id,
                        }),
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const payment = await response.json();
                    setAmountCrypto(payment.amount_crypto);
                    setAddress(payment.address);

                } catch (error) {
                    console.error('Error fetching create payment:', error);
                }

                setDisplayAmount(true);
                setDisplayCrypto(true);
                setDisplayAmountCrypto(true);
                setSelectVisible(false);

                setLoading(false);
            } else {
                setAlertMessage(languages.page.error_amount_in_dollars[selectLang])
                handleClick(Fade)();
            }
        }
    };

    const handleChange = (event) => {
        setCrypto(event.target.value);
    };
    const handleOpenModal = () => setOpenModal(true)
    const handleCloseModal = () => setOpenModal(false)


    const handleClick = (Transition) => () => {
        setTransition(Transition);
        setOpenAlert(true);
    };

    const handleClose = () => {
        setOpenAlert(false);
    };


    useEffect(() => {
        const fetchData = async (url, body) => {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            if (body) {
                options.body = JSON.stringify(body);
            }

            const response = await fetch(url, options);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return await response.json();
        };

        const fetchUserData = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.expand();

                const telegramID = window.Telegram.WebApp.initDataUnsafe.user.id;

                try {
                    const user = await fetchData(new dbUrls().users.getUser(), { user_id: telegramID });
                    setUser(user);
                    setSelectLang(user.language);

                    const [lang, stakingInfo, priceCati] = await Promise.all([
                        fetchData(new dbUrls().languages.getLang(), { page: 'detail_stake' }),
                        fetchData(new dbUrls().staking.detailInfo(), { user_id: telegramID, staking_number: id }),
                        fetchData(new dbUrls().merchant.priceCati()),
                    ]);

                    setLanguages(lang);
                    setStakingInfo(stakingInfo);
                    setCourseCati(priceCati.price);

                    // Кнопка НАЗАД
                    window.Telegram.WebApp.BackButton.show();
                    window.Telegram.WebApp.BackButton.onClick(() => { navigate('/stake') });
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchUserData();
    }, []);


    const getFontSizeForMoney = (moneyValue) => {
        const length = moneyValue.toString().length; // Преобразуем число в строку для получения длины
        if (length > 15) {
            return '24px';
        } else if (length > 12) {
            return '32px'; // Увеличиваем размер для длины от 13 до 15
        } else if (length > 10) {
            return '40px'; // Увеличиваем размер для длины от 11 до 12
        } else if (length > 8) {
            return '44px'; // Для длины от 9 до 10
        } else {
            return '52px'; // Для длины 8 и меньше
        }
    };

    if (loading) {
        return <LoadingCircle />;
    }

    return (<>
        <Box sx={{
            backgroundImage: 'url(/bg.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain', // Сохраняем 'contain' для мобильных
            backgroundPosition: 'center',
            maxWidth: '600px',
            height: '100%',
            overflowX: 'hidden', // Скрыть переполнение
            width: '100%', // Установить ширину на 100% для адаптивности
            // padding:'40px 0px 200px 0px',
            '@media (max-width: 600px)': { // Адаптивные стили для мобильных устройств
                height: '100%', // Автоматическая высота для мобильных
                backgroundSize: 'cover', // Изменить на cover для мобильных
            }
        }}>
            <Container maxWidth='600px' sx={{
                paddingTop: '20px',
                paddingBottom: '60px',
                height: '100%', // Убедитесь, что контейнер занимает всю высоту
            }}>
                <Header handleOpenModal={handleOpenModal} user={user} selectLang={selectLang} setSelectLang={setSelectLang} languages={languages.header} />
                <Navigation />
                <Stack sx={{ marginTop: '20px', marginBottom: '20px' }} spacing={2}>
                    <Stack>
                        <Typography textAlign='center' sx={{ color: '#764428', fontSize: '28px', fontWeight: '700' }}>
                            {languages.page.title[selectLang]}
                        </Typography>
                        <Typography textAlign='center' sx={{ color: '#BC805E', fontSize: '20px', fontWeight: '400' }}>
                            {(id === '1' ? languages.page.description_x3[selectLang] : languages.page.description_x8[selectLang]) + `${courseCati} $`}
                        </Typography>
                    </Stack>
                    <Stack alignItems='center'>
                        <Box sx={{
                            background: 'url(/pool.png)',
                            width: '361px',
                            height: '427px',
                            position: 'relative',
                            padding: '60px 25px'
                        }}>
                            <Box
                                component="img"
                                src={id === '1' ? "/star300.png" : "/star500.png"} // Укажите путь к вашему изображению
                                alt="Pool Table"
                                sx={{
                                    position: 'absolute',
                                    width: '71px',
                                    height: '77px',
                                    top: '10px',
                                    left: '5%',
                                }}
                            />
                            <Box
                                component="img"
                                src="/pool_table.png" // Укажите путь к вашему изображению
                                alt="Pool Table"
                                sx={{
                                    position: 'absolute',
                                    width: '110px',
                                    height: '36px',
                                    top: '30px',
                                    left: '33.5%',
                                }}
                            />
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    top: '47px',
                                    left: '49%',

                                    transform: 'translate(-50%, -50%)', // Центрируем текст по изображению
                                    color: '#764428', // Цвет текста
                                    fontWeight: '700', // Жирный текст
                                    textAlign: 'center', // Центрируем текст
                                }}
                            >
                                {languages.page.pool[selectLang]} {id}
                            </Typography>
                            <Stack direction='column' sx={{ marginTop: '20px' }}>
                                <Stack direction='row' justifyContent='center' alignItems='center' sx={{ marginBottom: '10px' }}>
                                    <Box sx={{
                                        background: stakingInfo.in_staking.toString().length > 10 ? "url('/money30x30.png')" : "url('/money.png')",
                                        backgroundRepeat: 'no-repeat',
                                        width: stakingInfo.in_staking.toString().length > 10 ? '40px' : '60px',
                                        height: stakingInfo.in_staking.toString().length > 10 ? '40px' : '60px',
                                        marginRight: '5px',
                                        flexShrink: 0,
                                        marginTop: stakingInfo.in_staking.toString().length > 10 ? '5px' : '0px'
                                    }}>

                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: getFontSizeForMoney(stakingInfo.in_staking),
                                            fontWeight: '800',
                                            color: "#FFD92D",
                                            WebkitTextStroke: stakingInfo.in_staking.toString().length > 10 ? '1px #DF671B' : '2px #DF671B',
                                            overflow: 'hidden', // Скрыть переполнение
                                            marginTop: stakingInfo.in_staking.toString().length > 10 ? '5px' : '0px',
                                            textOverflow: stakingInfo.in_staking.toString().length > 18 ? 'ellipsis' : '', // Добавить многоточие
                                            // whiteSpace: 'nowrap', // Запретить перенос строк
                                        }}>
                                        {stakingInfo.in_staking}
                                    </Typography>
                                </Stack>
                                <Stack direction='column' >
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.award[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {
                                                id === '1' ? (
                                                    pool.first.awards
                                                ) : pool.second.awards
                                            }{' '}Cati
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ bgcolor: '#CCA47C' }}></Divider>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.pool_apy[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {
                                                id === '1' ? (
                                                    pool.first.catiApy
                                                ) : pool.second.catiApy
                                            }
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ bgcolor: '#CCA47C' }}></Divider>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.my_award[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {stakingInfo.my_award} Cati
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ bgcolor: '#CCA47C' }}></Divider>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.awards_received[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {stakingInfo.collect} Cati
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ bgcolor: '#CCA47C' }}></Divider>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography sx={{ fontSize: "18px", fontWeight: '700', color: '#764428' }}>
                                            {languages.page.uncollected_awards[selectLang]}
                                        </Typography>
                                        <Typography sx={{ fontSize: "21px", fontWeight: '800', color: '#fff', WebkitTextStroke: '0.7px #764428' }}>
                                            {stakingInfo.uncollect} Cati
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>

                        </Box>
                    </Stack>
                </Stack>
                {
                    selectVisible ?
                        (<Stack alignItems='center' spacing={2}>
                            <Stack>
                                <Typography textAlign='start' sx={{ marginLeft: '10px', fontSize: '18px', color: '#764428', fontWeight: '700' }}>
                                    {languages.page.select_crypto[selectLang]}
                                </Typography>
                                <FormControl sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    maxWidth: '100%'
                                }} size="small">
                                    <InputLabel
                                        id="demo-select-small-label"
                                        sx={{
                                            color: '#764428',
                                            '&.Mui-focused': {
                                                color: '#764428', // Цвет лейбла при фокусе
                                            },
                                        }}
                                    >
                                        {languages.page.crypto[selectLang]}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={crypto}
                                        label="Age"
                                        onChange={handleChange}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    marginTop: '5px',
                                                    padding: '0px 5px',
                                                    backgroundColor: '#C19065', // Цвет фона для MuiPaper
                                                    border: '1px solid #764428', // Цвет границы
                                                    borderRadius: '10px', // Закругление углов
                                                },
                                            },
                                        }}
                                        sx={{
                                            width: '340px !important',
                                            display: 'flex',
                                            padding: '5px',
                                            borderRadius: '10px',
                                            bgcolor: '#E7D7B2',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#764428', // Цвет обводки по умолчанию
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#764428', // Цвет обводки при наведении
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#764428', // Цвет обводки при фокусе
                                            },
                                        }}
                                    >
                                        <MenuItem value="USDT TRC20" sx={{ bgcolor: '#E7D7B2 !important', margin: '5px 0px', border: '1px solid #764428', borderRadius: '10px' }}>
                                            <SelectCrypto value={"USDT TRC20"} iconUrl={"url('/usdt.svg')"} />
                                        </MenuItem>
                                        <MenuItem value="USDT ERC20 ETH" sx={{ bgcolor: '#E7D7B2 !important', margin: '5px 0px', border: '1px solid #764428', borderRadius: '10px' }}>
                                            <SelectCrypto value={"USDT ERC20 ETH"} iconUrl={"url('/usdt.svg')"} />
                                        </MenuItem>
                                        <MenuItem value="USDT TON" sx={{ bgcolor: '#E7D7B2 !important', margin: '5px 0px', border: '1px solid #764428', borderRadius: '10px' }}>
                                            <SelectCrypto value={"USDT TON"} iconUrl={"url('/usdt.svg')"} />
                                        </MenuItem>
                                        <MenuItem value="USDC ERC20" sx={{ bgcolor: '#E7D7B2 !important', margin: '5px 0px', border: '1px solid #764428', borderRadius: '10px' }}>
                                            <SelectCrypto value={"USDC ERC20"} iconUrl={"url('/usdc.svg')"} />
                                        </MenuItem>
                                        <MenuItem value="BTC" sx={{ bgcolor: '#E7D7B2 !important', margin: '5px 0px', border: '1px solid #764428', borderRadius: '10px' }}>
                                            <SelectCrypto value={"BTC"} iconUrl={"url('/btc.svg')"} />
                                        </MenuItem>
                                        <MenuItem value="ETH" sx={{ bgcolor: '#E7D7B2 !important', margin: '5px 0px', border: '1px solid #764428', borderRadius: '10px' }}>
                                            <SelectCrypto value={"ETH"} iconUrl={"url('/eth.svg')"} />
                                        </MenuItem>
                                        <MenuItem value="BNB Smart Chain" sx={{ bgcolor: '#E7D7B2 !important', margin: '5px 0px', border: '1px solid #764428', borderRadius: '10px' }}>
                                            <SelectCrypto value={"BNB Smart Chain"} iconUrl={"url('/bnb.svg')"} />
                                        </MenuItem>
                                        <MenuItem value="TRX" sx={{ bgcolor: '#E7D7B2 !important', margin: '5px 0px', border: '1px solid #764428', borderRadius: '10px' }}>
                                            <SelectCrypto value={"TRX"} iconUrl={"url('/trx.svg')"} />
                                        </MenuItem>
                                        <MenuItem value="TON" sx={{ bgcolor: '#E7D7B2 !important', margin: '5px 0px', border: '1px solid #764428', borderRadius: '10px' }}>
                                            <SelectCrypto value={"TON"} iconUrl={"url('/ton.svg')"} />
                                        </MenuItem>
                                        <MenuItem value="CATI TON" sx={{ bgcolor: '#E7D7B2 !important', margin: '5px 0px', border: '1px solid #764428', borderRadius: '10px' }}>
                                            <SelectCrypto value={"CATI TON"} iconUrl={"url('/catiton.svg')"} />
                                        </MenuItem>
                                        <MenuItem value="NOT TON" sx={{ bgcolor: '#E7D7B2 !important', margin: '5px 0px', border: '1px solid #764428', borderRadius: '10px' }}>
                                            <SelectCrypto value={"NOT TON"} iconUrl={"url('/notton.svg')"} />
                                        </MenuItem>
                                        <MenuItem value="DOGS TON" sx={{ bgcolor: '#E7D7B2 !important', margin: '5px 0px', border: '1px solid #764428', borderRadius: '10px' }}>
                                            <SelectCrypto value={"DOGS TON"} iconUrl={"url('/dogston.svg')"} />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack>
                                <Typography textAlign='start' sx={{ marginLeft: '10px', fontSize: '18px', color: '#764428', fontWeight: '700' }}>
                                    {languages.page.amount[selectLang]}
                                </Typography>
                                <Typography textAlign='start' sx={{ marginLeft: '10px', fontSize: '14px', color: '#764428', fontWeight: '700' }}>
                                    {languages.page.minimal_amount[selectLang]}
                                </Typography>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    sx={{
                                        width: '340px !important',
                                        marginTop: '10px',

                                        backgroundColor: '#DEC7A5', // Цвет фона для TextField
                                        borderRadius: '10px', // Закругление углов
                                        border: '1px solid #764428',
                                        marginBottom: '10px',
                                        '& .MuiOutlinedInput-root': {

                                            '& fieldset': {
                                                borderColor: 'transparent', // Цвет границы
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'transparent', // Цвет границы

                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'transparent', // Цвет границы

                                            },
                                        },
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <span style={{ color: '#764428', fontWeight: 'bold' }}>$</span>
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                            </Stack>

                        </Stack>)
                        : null
                }
                {
                    address && (
                        <Stack sx={{ marginTop: '20px', marginBottom: '20px' }} spacing={2}>
                            <Typography textAlign='center' sx={{ color: '#BC805E', fontSize: '20px', fontWeight: '400' }}>
                                {languages.page.payment_info[selectLang]}
                            </Typography>
                        </Stack>
                    )
                }
                <Stack alignItems='center'>
                    <Stack sx={{ marginTop: '0px' }}>
                        {
                            displayAmount
                                ? (
                                    <Stack sx={{ width: '340px' }}>
                                        <TextInput
                                            text='Amount'
                                            value={amount}
                                            langText={languages.page.dollars_amount[selectLang]}
                                            copyValue={amount}
                                        />
                                    </Stack>
                                ) : null
                        }
                        {
                            displayCrypto
                                ? (
                                    <Stack sx={{ width: '340px' }}>
                                        <TextInput
                                            text='Crypto'
                                            value={crypto}
                                            langText={languages.page.crypto[selectLang]}
                                            copyValue={crypto}
                                        />
                                    </Stack>
                                ) : null
                        }
                        {
                            displayAmountCrypto
                                ? (
                                    <Stack sx={{ width: '340px' }}>
                                        <TextInput
                                            text='CryptoAmount'
                                            value={amountCrypto}
                                            langText={languages.page.crypto_amount[selectLang] + crypto}
                                            copyValue={amountCrypto}
                                        />
                                    </Stack>
                                ) : null
                        }
                        {
                            address
                                ? (
                                    <Stack sx={{ width: '340px' }}>
                                        <TextInput
                                            text='Address'
                                            value={address.length > 25 ? `${address.slice(0, 25)}...` : address}
                                            langText={languages.page.address[selectLang]}
                                            copyValue={address}
                                        />
                                    </Stack>
                                ) : null
                        }
                    </Stack>
                    {
                        !address && (
                            <ButtonStake onClick={handleButtonClick} text={languages.page.title[selectLang]} />
                        )
                    }
                </Stack>
                <ModalWithdraw open={open} handleCloseModal={handleCloseModal} languages={languages.withdraw} selectLang={selectLang} />
                <Alert open={openAlert} handleClose={handleClose} Transition={transition} message={alertMessage}></Alert>
            </Container>
        </Box >
    </>);
}

export default Stacking;