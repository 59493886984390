class dbUsers {
    constructor() {
        this.url = 'https://catiairdrops.com/api/users';
    }

    getOrCreateUser() {
        return this.url + '/get_or_create_user';
    }

    getUser() {
        return this.url + '/get_user';
    }

    updateOpenWeb() {
        return this.url + '/update_open_web';
    }

    switchLanguage() {
        return this.url + '/switch_language';
    }
}

module.exports = dbUsers;