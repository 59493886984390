class dbLanguages {
    constructor() {
        this.url = 'https://catiairdrops.com/api/languages';
    }

    getLang() {
        return this.url + '/get_lang';
    }
}

module.exports = dbLanguages;