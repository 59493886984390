import { motion } from "framer-motion";
import { Box, Stack, Typography } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function TextInput({ text, value, langText, copyValue }) {

    return (
        <motion.div
            whileTap={{ scale: 0.95 }} // Уменьшение при нажатии
            initial={{ opacity: 0, y: -20 }} // Начальное состояние
            animate={{ opacity: 1, y: 0 }} // Конечное состояние
            exit={{ opacity: 0, y: -20 }} // Состояние при выходе
            transition={{
                type: 'spring',
                stiffness: 500,
                damping: 15
            }}>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack sx={{ width: '100%' }}>
                    <Typography textAlign='start' sx={{ fontSize: '18px', color: '#BC805E', fontWeight: '400' }}>
                        {langText}
                    </Typography>
                    <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{
                        width: '100%',
                        marginTop: '5px',
                        backgroundColor: '#DEC7A5', // Цвет фона для TextField
                        borderRadius: '10px', // Закругление углов
                        marginBottom: '10px',
                        padding: '10px 10px 10px 10px'
                    }}>
                        <Stack direction='row' alignItems='center'>
                            {
                                text === 'Crypto' && value === "USDT TRC20"
                                    ? <Box sx={{
                                        background: "url('/usdt.svg')",
                                        backgroundRepeat: 'no-repeat',
                                        width: '32px',
                                        height: '32px',
                                        marginRight: '5px'
                                    }}></Box>
                                    : text === 'Crypto' && value === "USDT ERC20"
                                        ? <Box sx={{
                                            background: "url('/usdt.svg')",
                                            backgroundRepeat: 'no-repeat',
                                            width: '32px',
                                            height: '32px',
                                            marginRight: '5px'
                                        }}></Box>
                                        : text === 'Crypto' && value === "BTC"
                                            ? <Box sx={{
                                                background: "url('/btc.svg')",
                                                backgroundRepeat: 'no-repeat',
                                                width: '32px',
                                                height: '32px',
                                                marginRight: '5px'
                                            }}></Box>
                                            : text === 'Crypto' && value === "ETH"
                                                ? <Box sx={{
                                                    background: "url('/eth.svg')",
                                                    backgroundRepeat: 'no-repeat',
                                                    width: '32px',
                                                    height: '32px',
                                                    marginRight: '5px'
                                                }}></Box>
                                                : text === 'Crypto' && value === "BNB"
                                                    ? <Box sx={{
                                                        background: "url('/bnb.svg')",
                                                        backgroundRepeat: 'no-repeat',
                                                        width: '32px',
                                                        height: '32px',
                                                        marginRight: '5px'
                                                    }}></Box>
                                                    : text === 'Crypto' && value === "TRX"
                                                        ? <Box sx={{
                                                            background: "url('/trx.svg')",
                                                            backgroundRepeat: 'no-repeat',
                                                            width: '32px',
                                                            height: '32px',
                                                            marginRight: '5px'
                                                        }}></Box>
                                                        : text === 'Crypto' && value === "TON"
                                                            ? <Box sx={{
                                                                background: "url('/ton.svg')",
                                                                backgroundRepeat: 'no-repeat',
                                                                width: '32px',
                                                                height: '32px',
                                                                marginRight: '5px'
                                                            }}></Box>
                                                            : text === 'Crypto' && value === "CATI TON"
                                                                ? <Box sx={{
                                                                    background: "url('/catiton.svg')",
                                                                    backgroundRepeat: 'no-repeat',
                                                                    width: '32px',
                                                                    height: '32px',
                                                                    marginRight: '5px'
                                                                }}></Box>
                                                                : text === 'Crypto' && value === "NOT TON"
                                                                    ? <Box sx={{
                                                                        background: "url('/notton.svg')",
                                                                        backgroundRepeat: 'no-repeat',
                                                                        width: '32px',
                                                                        height: '32px',
                                                                        marginRight: '5px'
                                                                    }}></Box>
                                                                    : text === 'Crypto' && value === "DOGS TON"
                                                                        ? <Box sx={{
                                                                            background: "url('/dogston.svg')",
                                                                            backgroundRepeat: 'no-repeat',
                                                                            width: '32px',
                                                                            height: '32px',
                                                                            marginRight: '5px'
                                                                        }}></Box>
                                                                        : text === 'Crypto' && value === "USDT TON"
                                                                            ? <Box sx={{
                                                                                background: "url('/usdt.svg')",
                                                                                backgroundRepeat: 'no-repeat',
                                                                                width: '32px',
                                                                                height: '32px',
                                                                                marginRight: '5px'
                                                                            }}></Box>
                                                                            : text === 'Crypto' && value === "USDC ERC20"
                                                                                ? <Box sx={{
                                                                                    background: "url('/usdc.svg')",
                                                                                    backgroundRepeat: 'no-repeat',
                                                                                    width: '32px',
                                                                                    height: '32px',
                                                                                    marginRight: '5px'
                                                                                }}></Box>
                                                                                : null

                            }
                            {
                                text === 'Amount' ? <Box sx={{
                                    background: "url('/dollar_icon.svg')",
                                    backgroundRepeat: 'no-repeat',
                                    width: '32px',
                                    height: '32px',
                                    marginRight: '5px'
                                }}></Box> : null
                            }
                            {
                                text === 'CryptoAmount' ? <Box sx={{
                                    background: "url('/binance_icon.svg')",
                                    backgroundRepeat: 'no-repeat',
                                    width: '32px',
                                    height: '32px',
                                    marginRight: '5px'
                                }}></Box> : null
                            }
                            {
                                text === 'Address' ? <Box sx={{
                                    background: "url('/crypto_wallet.svg')",
                                    backgroundRepeat: 'no-repeat',
                                    width: '32px',
                                    height: '32px',
                                    marginRight: '5px'
                                }}></Box> : null
                            }
                            <Typography textAlign='start' sx={{ fontSize: '16px', color: '#764428', fontWeight: '700' }}>

                                {value}

                            </Typography>
                        </Stack>
                        <Stack>
                            {
                                text === 'CryptoAmount' || text === 'Address'
                                    ? <ContentCopyIcon onClick={() => {
                                        navigator.clipboard.writeText(copyValue) // Копируем значение в буфер обмена
                                            .then(() => {
                                                window.Telegram.WebApp.showAlert('✅ Скопировано!');

                                            })
                                    }} sx={{ color: '#764428', padding: '3px', fontSize: '32px' }} />
                                    : null
                            }

                        </Stack>

                    </Stack>

                </Stack>
            </Stack>
        </motion.div>
    );
}

export default TextInput;