import { Box, Container, Stack, Typography } from "@mui/material";
import Header from "../Components/Header";
import Navigation from "../Components/Navigation";
import Timer from "../Components/Timer";
import ButtonConnect from "../Components/Buttons/ButtonConnect";
import { useState, useEffect } from "react";
import ModalWithdraw from "../Components/ModalWithdraw";

import LoadingCircle from "../Components/LoadingCircle";

import dbUrls from '../database/dbUrls';

import { TonConnectButton } from "@tonconnect/ui-react";


function Airdrop() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    const [selectLang, setSelectLang] = useState('en');
    const [languages, setLanguages] = useState({});

    const [open, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true)
    const handleCloseModal = () => setOpenModal(false)

    useEffect(() => {
        const fetchUserData = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.expand();

                const telegramID = window.Telegram.WebApp.initDataUnsafe.user.id;

                try {
                    const response = await fetch(new dbUrls().users.getUser(), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ user_id: telegramID }),
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const user = await response.json();
                    setUser(user);
                    setSelectLang(user.language);

                    try {
                        const response = await fetch(new dbUrls().languages.getLang(), {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ page: 'airdrop' }),
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }

                        const lang = await response.json();
                        setLanguages(lang);

                    } catch (error) {
                        console.error('Error fetching lang data:', error);
                    }

                    // Кнопка НАЗАД
                    window.Telegram.WebApp.BackButton.hide();
                } catch (error) {
                    console.error('Error fetching user data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchUserData();
    }, []);

    if (loading) {
        return <LoadingCircle />;
    }

    return (<>
        <Box sx={{
            backgroundImage: 'url(bg.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain', // Сохраняем 'contain' для мобильных
            backgroundPosition: 'center',
            maxWidth: '600px',
            height: '100vh',
            overflow: 'hidden', // Скрыть переполнение
            width: '100%', // Установить ширину на 100% для адаптивности
            '@media (max-width: 600px)': { // Адаптивные стили для мобильных устройств
                height: '100vh', // Автоматическая высота для мобильных
                backgroundSize: 'cover', // Изменить на cover для мобильных
            }
        }}>
            <Container maxWidth='600px' sx={{
                paddingTop: '20px',
                paddingBottom: '40px',
                height: '100%', // Убедитесь, что контейнер занимает всю высоту
            }}>
                <Header handleOpenModal={handleOpenModal} user={user} selectLang={selectLang} setSelectLang={setSelectLang} languages={languages.header} />
                <Navigation />
                <Stack direction='column' justifyContent='space-between' sx={{ height: '100%' }}>
                    <Stack sx={{ marginTop: '20px' }}>
                        <Typography textAlign='center' sx={{
                            fontWeight: '800',
                            fontSize: '32px',
                            WebkitTextStroke: '1.5px #954614',
                            letterSpacing: 0,
                            background: 'linear-gradient(to bottom, #FFFFFF, #c4c4c4)', // Градиент от белого к серому
                            WebkitBackgroundClip: 'text',
                            color: 'transparent', // Делаем цвет текста прозрачным, чтобы увидеть градиент
                        }}>
                            {languages.page.title[selectLang]}
                        </Typography>
                        <Typography textAlign='center' sx={{ color: '#BC805E', fontSize: '20px', fontWeight: '400' }}>
                            {languages.page.description[selectLang]}
                        </Typography>
                        <Timer selectLang={selectLang} />
                        <Box sx={{
                            backgroundImage: 'url(airdrop_bg.png)',
                            marginTop: '20px',
                            height: '40vh',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain', // Сохраняем 'contain' для мобильных
                            backgroundPosition: 'center',
                            overflow: 'hidden', // Скрыть переполнение
                        }}></Box>
                    </Stack>
                    <Stack mb={8}>
                        <TonConnectButton style={{
                            width: '362px',
                            height: '61px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            margin: '20px 0px'
                        }} />
                    </Stack>
                </Stack>
                <ModalWithdraw open={open} handleCloseModal={handleCloseModal} languages={languages.withdraw} selectLang={selectLang} />
            </Container>
        </Box >
    </>);
}

export default Airdrop;